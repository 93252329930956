<template>
  <div class="app-container">
    <div class="welcome">欢迎使用发票服务</div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.app-container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .welcome {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 30px;
  }
}
</style>

